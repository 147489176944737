<template>
  <div>
    <div class="flex justify-between items-center">
      <VTitle :title="$t('app.details')" class="section-title" />

      <LangDropdown
        v-if="hasLangDropdown"
        :lang="lang"
        :disabled="isActive"
        :texts="resource.texts"
        :endpoint="`${endpoint}.delete-language`"
        :message="message"
        attribute="evaluation_id"
        @update="onUpdateLang"
        @click:clear="onClickClearUpdateMessage"
        @cleared="$emit('refresh')"
      />
    </div>

    <Form
      :id="id"
      :is-loading="isLoading"
      :data="data"
      :resource-value="resourceValue"
      :rules="rules"
      :type="type"
      :localized-fields="localizedFields"
      :options="options"
      @update:tab="updateTab"
      @click:cancel="$emit('click:cancel')"
      @click:save="$emit('click:save', $event)"
      @refresh="$emit('refresh')"
    />

    <Logs :data="data" class="mt-8" hide-action />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTabs from "@/composables/useTabs";
import useLangDropdown from "@/composables/useLangDropdown";
import useOptions from "@/composables/useOptions";
// Components
import VTitle from "@/components/VTitle";
import LangDropdown from "@/components/LangDropdown";
import Logs from "@/components/Logs";
import Form from "./Form";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    VTitle,
    Form,
    Logs,
    LangDropdown
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    },
    resourceValue: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    endpoint: {
      type: String,
      required: true
    },
    localizedFields: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: value =>
        [evaluationTypes.QUIZ, evaluationTypes.OBSERVATION].includes(value)
    }
  },
  emits: [
    "click:cancel",
    "click:save",
    "add:instructor",
    "remove:instructor",
    "add:competency",
    "remove:competency",
    "remove:text"
  ],
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Data
    const deletable = ref("");

    // Computed
    const data = computed(() => props.resource);
    const texts = computed(() => data.value.texts);
    const status = computed(() => data.value.status);
    const isActive = computed(() => status.value === ALL_OPTIONS.ACTIVE.value);
    const hasLangDropdown = computed(() => {
      const isDetails = isCurrentTab("details");
      const isQuestions = isCurrentTab("questions");
      const isRequirements = isCurrentTab("requirements");

      return isDetails || isQuestions || isRequirements;
    });
    const message = computed(() => {
      return t("app.delete_language", {
        name: deletable.value?.name
      });
    });

    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { lang, updateLang } = useLangDropdown({
      texts,
      localizedFields: props.localizedFields
    });
    const { updateTab, currentTab, isCurrentTab } = useTabs("details");

    // Methods
    const onClickClearUpdateMessage = item => {
      deletable.value = item;
    };

    return {
      data,
      message,
      hasLangDropdown,
      onClickClearUpdateMessage,
      // useForm
      isActive,
      // useTabs
      updateTab,
      currentTab,
      // useLangDropdown
      lang,
      onUpdateLang: updateLang
    };
  }
};
</script>
