<template>
  <Details
    :id="id"
    :resource="data"
    :resource-value="value"
    :rules="rules"
    :type="evaluationTypes.OBSERVATION"
    :options="options"
    :is-loading="isLoading"
    :endpoint="endpoint"
    :localized-fields="localizedFields"
    @click:cancel="$emit('click:cancel')"
    @click:save="$emit('click:save', $event)"
    @refresh="$emit('refresh')"
  />
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTags from "@/composables/useTags";
import useTexts from "@/composables/useTexts";
import useObservation from "@/composables/useObservation";
// Components
import Details from "../Details";
// Constants
import evaluationTypes from "@/constants/evaluationTypes";

export default {
  components: {
    Details
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const {
      endpoint,
      localizedFields,
      value,
      rules,
      options
    } = useObservation();
    const { getText, getTextValues } = useTexts();
    const { getTagValues } = useTags();

    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.details")} - ${t("app.observations", 2)} - ${t("app.evaluations", 2)} - ${t("app.teaching")}`;

      const name = getText(props.resource?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    const data = computed(() => ({
      ...props.resource,
      ...getTextValues(props.resource?.texts, localizedFields),
      tags: getTagValues(props.resource?.tags)
    }));

    return {
      evaluationTypes,
      documentTitle,
      // useQuiz
      endpoint,
      data,
      localizedFields,
      value,
      rules,
      options
    };
  }
};
</script>
